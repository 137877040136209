import React from "react";

export function ContactInfo() {
    return (
        <div className="d-flex flex-column align-items-center">
            <h2 className="fs-1 mb-3 text-uppercase fw-bold">Where To Find Us</h2>
            <p className="mb-5">7717 W. 95th Street, Hickory Hills, Illinois            </p>
            <h3>Opening Hours</h3>
            <p className="m-0">Mon - Fri: 09:00 AM - 12:00 AM</p>
            <p>Sat - Sun: 09:00 AM - 01:00 AM</p>
        </div>
    )
}