import React from 'react';
import './Sandwiches.css'; // Optional: Create corresponding CSS for styling

function Sandwiches() {
    const items = [
        { name: 'Fish Burger', prices: '$8.99 / $10.99' },
        { name: 'Fish Sandwich', prices: '$8.99 / $10.99' },
        { name: 'Cheese Burger', prices: '$8.99 / $10.99' },
        { name: 'Chicken Sandwich (Crispy or Spicy)', prices: '$8.99 / $10.99' },
        { name: 'Grilled Chicken Sandwich', prices: '$8.99 / $10.99' },
        { name: 'Philly Steak Sandwich', prices: '$8.99 / $10.99' },
        { name: 'Philly Chicken Sandwich', prices: '$8.99 / $10.99' },
        { name: 'Gyro Sandwich', prices: '$8.99 / $10.99' },
        { name: 'Gyro Plate', prices: '$11.99 / $13.99' },
        { name: 'Italian Beef Sandwich', prices: '$8.99 / $10.99' },
    ];

    return (
        <div className='sandwiches'>
            <h2>Sandwiches</h2>
            <p>All combo sandwiches served with fries & pop:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.prices}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Sandwiches;

