import React from 'react';
import './ChickenWings.css'; // Optional: Create corresponding CSS for styling

function ChickenWings() {
    const items = [
        { name: '4 PC Wing Dinner', price: '$8.99' },
        { name: '6 PC Wing Dinner', price: '$9.99' },
        { name: '10 PC Wing Dinner', price: '$17.99' },
        { name: '15 PC Wing Dinner', price: '$25.99' },
        { name: '20 PC Wing Dinner', price: '$29.99' },
        { name: '25 PC Wing Dinner', price: '$39.99' },
        { name: '30 PC Wing Dinner', price: '$44.99' },
        { name: '50 PC Wing Dinner', price: '$64.99' },
    ];

    const dippingOptions = 'Extra charge for dipping';

    return (
        <div className='chicken-wings'>
            <h2>Chicken Wings</h2>
            <p>{dippingOptions}:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ChickenWings;

