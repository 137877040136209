import React from 'react';
import './ChickenMix.css'; // Optional: Create corresponding CSS for styling

function ChickenMix() {
    const items = [
        { name: '4 PC Dinner', price: '$8.99' },
        { name: '6 PC Dinner', price: '$9.99' },
        { name: '10 PC Dinner', price: '$17.99' },
        { name: '15 PC Dinner', price: '$25.99' },
        { name: '20 PC Dinner', price: '$29.99' },
        { name: '25 PC Dinner', price: '$39.99' },
        { name: '30 PC Dinner', price: '$44.99' },
        { name: '50 PC Dinner', price: '$64.99' },
        { name: '75 PC Dinner', price: '$94.99' },
        { name: '100 PC Dinner', price: '$132.99' },
    ];

    return (
        <div className='chicken-mix'>
            <h2>Chicken Mix (Legs & Thighs)</h2>
            <p>All dinners served with fries, coleslaw, and bread:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ChickenMix;

