import React from 'react';
import './SideOrders.css'; // Optional: Create corresponding CSS for styling

function SideOrders() {
    const items = [
        { name: 'Spaghetti', price: '$5.99' },
        { name: 'Cole Slaw (S)', price: '$0.99' },
        { name: 'Cole Slaw (L)', price: '$1.99' },
        { name: 'Jalapeno Pepper', price: '$0.99' },
        { name: 'French Fries (S)', price: '$0.99' },
        { name: 'French Fries (L)', price: '$1.99' },
        { name: 'Box of Fries', price: '$4.99' },
        { name: 'Half Pan of Fries', price: '$19.99' },
        { name: 'Full Pan of Fries', price: '$39.99' },
        { name: 'Okra', price: '$2.99' },
        { name: 'Onion Rings (15PC)', price: '$3.99' },
        { name: 'Hush Puppies (15PC)', price: '$3.99' },
        { name: 'Cheese Sticks W/Fries (6PC)', price: '$5.99' },
        { name: 'Breaded Jalapenos', price: '$3.99' },
        { name: '1 Pizza or 1 Gyro Pie W/Fries', price: '$6.99' },
        { name: '2 Pizza or 2 Gyro Pie W/Fries', price: '$13.99' },
    ];

    return (
        <div className='side-orders'>
            <h2>Side Orders</h2>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SideOrders;

