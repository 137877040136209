import React from 'react';
import './ChickenDinners.css'; // Optional: Create corresponding CSS for styling

function ChickenDinners() {
    const items = [
        { name: 'Chicken Tenders', small: '$12.99', large: '$14.99' },
        { name: 'Chicken Gizzards', small: '$12.99', large: '$14.99' },
        { name: 'Chicken Nuggets', small: '$12.99', large: '$14.99' },
    ];

    return (
        <div className='chicken-dinners'>
            <h2>Chicken Dinners</h2>
            <p>All dinners served with fries, coleslaw, and bread:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Small</th>
                        <th>Large</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.small}</td>
                            <td>{item.large}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ChickenDinners;

