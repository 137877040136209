import React from 'react';
import './Menu.css';
import { Link, Outlet } from 'react-router-dom';

// Array of image paths
const images = [
    require('../utils/img/breakfast.jpg'),
    require('../utils/img/dessert.jpg'),
    require('../utils/img/dinner.jpg'),
    require('../utils/img/lunch.jpg'),
    require('../utils/img/menu-img.jpg')
];

const categories = [
    { id: 1, name: 'Fish Dinners', path: 'fish-dinners' },
    { id: 2, name: 'Shrimp Dinners', path: 'shrimp-dinners' },
    { id: 3, name: 'Family Meals', path: 'family-meals' },
    { id: 4, name: 'Family Combo Meals', path: 'family-combo-meals' },
    { id: 5, name: 'Combo Dinners', path: 'combo-dinners' },
    { id: 6, name: 'Chicken Wings', path: 'chicken-wings' },
    { id: 7, name: 'Chicken Mix', path: 'chicken-mix' },
    { id: 8, name: 'Boneless Wings', path: 'boneless-wings' },
    { id: 9, name: 'Chicken Dinners', path: 'chicken-dinners' },
    { id: 10, name: 'Party Wings', path: 'party-wings' },
    { id: 11, name: 'Side Orders', path: 'side-orders' },
    { id: 12, name: 'Sandwiches', path: 'sandwiches' },
    { id: 13, name: 'Salads', path: 'salads' },
    { id: 14, name: 'Dessert', path: 'dessert' },
    { id: 15, name: 'Drinks', path: 'drinks' },
    { id: 16, name: 'Kids Meals', path: 'kids-meals' }
];

function Menu() {
    return (
        <div className='menu-page'>
            <header className='mt-5'>
                <div className='container h-100 d-flex align-items-center justify-content-center'>
                    <h1 className='text-dark'>Menu</h1>
                </div>
            </header>

            <div className='container my-5'>
                <div className='row'>
                    {categories.map((category) => {
                        // Randomly select an image
                        const randomImage = images[Math.floor(Math.random() * images.length)];
                        return (
                            <div key={category.id} className='col-6 col-md-4 col-lg-3 mb-4'>
                                <Link to={category.path} className='text-decoration-none'>
                                    <div className='card category-card'>
                                        <img src={randomImage} className='card-img-top' alt={category.name} />
                                        <div className='card-body'>
                                            <h5 className='card-title text-center text-dark'>{category.name}</h5>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>

            {/* Render nested routes here */}
            <Outlet />
        </div>
    );
}

export default Menu;
