import React from 'react';
import './FamilyMeals.css'; // Optional: Create corresponding CSS for styling

function FamilyMeals() {
    const items = [
        { name: '12 PC Fish', price: '$43.99' },
        { name: '20 PC Fish', price: '$64.99' },
        { name: '50 PC Fish', price: '$159.99' },
        { name: 'Half Pan of Catfish Nuggets', price: '$53.99' },
        { name: 'Full Pan of Catfish Nuggets', price: '$99.99' },
        { name: '20 PC Fish Strips', price: '$52.99' },
        { name: '40 PC Fish Strips', price: '$94.99' },
    ];

    return (
        <div className='family-meals'>
            <h2>Family Meals</h2>
            <p>Served with fries, coleslaw, and a loaf of bread:</p>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.name}: {item.price}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FamilyMeals;

