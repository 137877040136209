import React from 'react';
import './Drinks.css'; // Optional: Create corresponding CSS for styling

function Drinks() {
    const items = [
        { name: 'Water', price: '$1.20' },
        { name: 'Mistic', price: '$2.99' },
        { name: 'Arizona', price: '$1.99' },
        { name: '20oz Pop', price: '$2.45' },
    ];

    return (
        <div className='drinks'>
            <h2>Drinks</h2>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Drinks;

