import React from 'react';
import './KidsMeals.css'; // Optional: Create corresponding CSS for styling

function KidsMeals() {
    const price = '$6.99 each';
    const description = 'Served with fries & drink:';
    const items = [
        '5 PC Chicken Nuggets',
        '2 Wings',
        '2 PC Chicken Tenders',
    ];

    return (
        <div className='kids-meals'>
            <h2>Kids Meals</h2>
            <p>{price}, {description}</p>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
}

export default KidsMeals;

