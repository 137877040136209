import React from 'react';
import './ShrimpDinners.css'; // Optional: Create corresponding CSS for styling

function ShrimpDinners() {
    const items = [
        { name: 'Medium Shrimp', small: '$15.99', large: '$23.99' },
        { name: 'Jumbo Shrimp', small: '$15.99', large: '$25.99' },
    ];

    return (
        <div className='shrimp-dinners'>
            <h2>Shrimp Dinners</h2>
            <p>All dinners served with fries, coleslaw, and bread:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Small</th>
                        <th>Large</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.small}</td>
                            <td>{item.large}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ShrimpDinners;

