import React from 'react';
import './FishDinners.css'; // Optional: Create corresponding CSS for styling

function FishDinners() {
    const items = [
        { name: 'Catfish Fillet', small: '$12.99', large: '$15.99' },
        { name: 'Fish Strips', small: '$12.99', large: '$15.99' },
        { name: 'Catfish Nuggets', small: '$12.99', large: '$15.99' },
        { name: 'Ocean Perch', small: '$12.99', large: '$15.99' },
        { name: 'Tilapia Fillet', small: '$12.99', large: '$15.99' },
        { name: 'Jack Salmon', small: '$12.99', large: '$15.99' },
        { name: 'Catfish', small: '$12.99', large: '$15.99' },
        { name: 'Cat Fillets', small: '$12.99', large: '$15.99' },
        { name: 'Buffalo Fish', small: '$12.99', large: '$15.99' },
        { name: 'Oysters', small: '$15.99', large: '$19.99' },
    ];

    const additionalOptions = {
        allSteaks: 'Add $3.00',
        largeOrders: 'Add $6.99',
    };

    return (
        <div className='fish-dinners'>
            <h2>Fish Dinners</h2>
            <p>All dinners served with fries, coleslaw, and bread:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Small</th>
                        <th>Large</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.small}</td>
                            <td>{item.large}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className='additional-options'>
                <p>{additionalOptions.allSteaks}</p>
                <p>{additionalOptions.largeOrders}</p>
            </div>
        </div>
    );
}

export default FishDinners;

