import './App.css';
import { Link, Routes, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './pages/Home';
import Menu from './pages/Menu';
import About from './pages/About';
import Contact from './pages/Contact';

// Importing the new menu category components
import FishDinners from './pages/menu/FishDinners';
import ShrimpDinners from './pages/menu/ShrimpDinners';
import FamilyMeals from './pages/menu/FamilyMeals';
import FamilyComboMeals from './pages/menu/FamilyComboMeals';
import ComboDinners from './pages/menu/ComboDinners';
import ChickenWings from './pages/menu/ChickenWings';
import ChickenMix from './pages/menu/ChickenMix';
import BonelessWings from './pages/menu/BonelessWings';
import ChickenDinners from './pages/menu/ChickenDinners';
import PartyWings from './pages/menu/PartyWings';
import SideOrders from './pages/menu/SideOrders';
import Sandwiches from './pages/menu/Sandwiches';
import Salads from './pages/menu/Salads';
import Dessert from './pages/menu/Dessert';
import Drinks from './pages/menu/Drinks';
import KidsMeals from './pages/menu/KidsMeals';

function App() {
  return (
    <div>
      <Navbar expand="lg" className='fixed-top bg-body-tertiary shadow'>
        <Container>
          <Navbar.Brand>
            <Link to="/" className='navbar-brand text-success fw-semibold'>
              Sharks Fish and Chicken
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto justify-content-end w-100'>
              <Nav.Link href='/' className='active text-uppercase'>Home</Nav.Link>
              <Nav.Link href='/menu' className='text-uppercase'>Menu</Nav.Link>
              <Nav.Link href='/about' className='text-uppercase'>About</Nav.Link>
              <Nav.Link href='/contact' className='text-uppercase'>Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/menu/fish-dinners' element={<FishDinners />} />
        <Route path='/menu/shrimp-dinners' element={<ShrimpDinners />} />
        <Route path='/menu/family-meals' element={<FamilyMeals />} />
        <Route path='/menu/family-combo-meals' element={<FamilyComboMeals />} />
        <Route path='/menu/combo-dinners' element={<ComboDinners />} />
        <Route path='/menu/chicken-wings' element={<ChickenWings />} />
        <Route path='/menu/chicken-mix' element={<ChickenMix />} />
        <Route path='/menu/boneless-wings' element={<BonelessWings />} />
        <Route path='/menu/chicken-dinners' element={<ChickenDinners />} />
        <Route path='/menu/party-wings' element={<PartyWings />} />
        <Route path='/menu/side-orders' element={<SideOrders />} />
        <Route path='/menu/sandwiches' element={<Sandwiches />} />
        <Route path='/menu/salads' element={<Salads />} />
        <Route path='/menu/dessert' element={<Dessert />} />
        <Route path='/menu/drinks' element={<Drinks />} />
        <Route path='/menu/kids-meals' element={<KidsMeals />} />
        <Route path='/about' element={<About />} />
        <Route path='/contact' element={<Contact />} />
      </Routes>

      <footer className='bg-body-tertiary'>
        <p className='p-3 m-0 text-center'>2025 copyright @ Sharks Fish and Chicken, made by Bilal Alkhatib +962781783030</p>
      </footer>
    </div>
  );
}

export default App;
