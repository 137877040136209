import React from 'react';
import './Salads.css'; // Optional: Create corresponding CSS for styling

function Salads() {
    const items = [
        { name: 'Garden Salad', price: '$6.99' },
        { name: 'Crispy Chicken', price: '$9.99' },
        { name: 'Grilled Chicken', price: '$9.99' },
        { name: 'Grilled Shrimp', price: '$10.99' },
        { name: 'Nachos W/Cheese', price: '$6.99' },
        { name: 'Nachos W/Meat', price: '$9.99' },
    ];

    return (
        <div className='salads'>
            <h2>Salads</h2>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Salads;

