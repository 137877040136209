import React from 'react';
import './ComboDinners.css'; // Optional: Create corresponding CSS for styling

function ComboDinners() {
    const twoItems = '$14.99';
    const threeItems = '$18.99';
    const items = [
        'Catfish Fillet (1PC)',
        'Perch (2PC)',
        'Jack Salmon (2PC)',
        'Wings (3PC)',
        'Gizzards (10PC)',
        'Tilapia (1PC)',
        'Catfish (2PC)',
        'Pizza Pie (1PC)',
        'Catfish Nugget (10PC)',
        'Chicken Tenders (2PC)',
        'Medium Shrimp (8PC)',
        'Jumbo Shrimp (3PC)',
    ];

    return (
        <div className='combo-dinners'>
            <h2>Combo Dinners</h2>
            <p>Your choice of 2 items for {twoItems} or 3 items for {threeItems}:</p>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>{item}</li>
                ))}
            </ul>
        </div>
    );
}

export default ComboDinners;

