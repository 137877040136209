import React from 'react';
import './Dessert.css'; // Optional: Create corresponding CSS for styling

function Dessert() {
    const items = [
        { name: 'Cheesecake', price: '$3.99' },
        { name: 'Cake', price: '$3.99' },
        { name: 'Taffy Grapes', price: '$4.99' },
        { name: 'Cookies', price: '$0.99' },
    ];

    return (
        <div className='dessert'>
            <h2>Dessert</h2>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Dessert;

