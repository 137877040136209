import React from 'react';
import './FamilyComboMeals.css'; // Optional: Create corresponding CSS for styling

function FamilyComboMeals() {
    const items = [
        { name: '10 Wings', price: '$57.99' },
        { name: '10 Perch', price: '$57.99' },
        { name: '10 Tenders', price: '$57.99' },
        { name: '10 Catfish', price: '$57.99' },
        { name: '10 Jack Salmon', price: '$57.99' },
        { name: '10 Cat Fillet', price: '$57.99' },
    ];

    return (
        <div className='family-combo-meals'>
            <h2>Family Combo Meals</h2>
            <p>Served with a box of fries and your choice of any 2 items:</p>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.name}: {item.price}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default FamilyComboMeals;

