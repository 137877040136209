import React from 'react';
import './PartyWings.css'; // Optional: Create corresponding CSS for styling

function PartyWings() {
    const items = [
        { name: '6 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$9.99' },
        { name: '10 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$17.99' },
        { name: '15 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$25.99' },
        { name: '20 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$29.99' },
        { name: '25 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$39.99' },
        { name: '30 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$44.99' },
        { name: '50 PC Wing Dinner (1/2 wings hot or honey BBQ)', price: '$64.99' },
    ];

    return (
        <div className='party-wings'>
            <h2>Party Wings</h2>
            <p>1/2 wings hot or honey BBQ:</p>
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    {items.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default PartyWings;

